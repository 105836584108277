import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the settings feature.
 */
enum SettingsPermissionsEnum {
  None = 0,

  /**
   * Organization settings can be edited.
   */
  ManageOrganizationSettings = 1,
}

export const SettingsPermissions = permissions(SettingsPermissionsEnum, {
  featureId: 'Settings',
});
